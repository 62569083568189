export const themeDetails = {
  company: 'Muonion Sähköosuuskunta',
  brandName: 'Assari',
  theme: 'mso',
  services: ['electricity', 'contractInfo'],
  incomingServices: [],
  customerServiceEmail: 'toimisto@muonionsahkoosuuskunta.fi',
  feedbackEmail: 'toimisto@muonionsahkoosuuskunta.fi',
}

/* added property to control drillable value -ADP */
export const Options = {
  quarterlyDataAllowed: false, 
}

export const styledComponentsThemes = {
  default: {
    'nav-color': '#005685',
    'inline-nav-color': '#005685',
    'nav-color:hover': '#009fe3',
  },
  inverted: {
    'nav-color': '#fff',
    'inline-nav-color': '#fff',
    'nav-color:hover': '#fff',
  },
}

export const textStrings = {
  home: {
    introduction: 'Tervetuloa Muonion Sähköosuuskunnan Onlinepalvelu Assariin!',
    subHeader:
      'Täältä löydät ajankohtaiset tiedot liittyen energiankulutukseesi.',
  },
}

export const footerContent = {
  contactInfo: [
    {
      icon: 'fa fa-map-marker-alt',
      type: 'address',
      href: '',
      title: 'Käyntiosoite',
      content: ['Torniontie 146,', '99300 Muonio'],
    },
    {
      icon: 'fa fa-globe',
      type: 'website',
      href: 'https://www.muonionsahkoosuuskunta.fi',
      title: 'Verkkosivut',
      content: 'www.muonionsahkoosuuskunta.fi',
    },
    {
      icon: 'fa fa-phone',
      type: 'tel',
      href: 'tel:0405311394',
      title: 'Asiakaspalvelu',
      content: 'puh. 040 531 1394',
    },
    {
      icon: 'fa fa-envelope',
      type: 'email',
      href: 'mailto:toimisto@muonionsahkoosuuskunta.fi',
      title: 'Sähköpostiosoite',
      content: 'toimisto@muonionsahkoosuuskunta.fi',
    },
  ],
  officeHours: ['ma-pe 8-11 ja 12-16'],
  faultReports: {
    intro: 'Vikailmoitukset työajan ulkopuolella',
    contacts: [
      {
        href: 'tel:016532348',
        text: 'puh. ',
        linkText: '016 532 348',
      },
      {
        href: 'mailto:toimisto@muonionsahkoosuuskunta.fi',
        text: '',
        linkText: 'toimisto@muonionsahkoosuuskunta.fi',
      },
    ],
  },
  website: 'https://www.muonionsahkoosuuskunta.fi',
}
